/* You can add global styles to this file, and also import other style files */
@import './assets/_variables';
html, body { height: 100% }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}
.material-icons {
  font-family: Material Icons, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
@import '../node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.css';
